import { escapeHighlightedString as esc } from 'autocomplete.js';

import { reportError } from '@ftf/lib/reporting';
import { toTitleCase } from '@ftf-old/util/Formatter';

const dealTemplate = (hit, answer, isPersistent) => {
  const { _highlightResult: deal } = hit;

  try {
    const address =
      deal.address && deal.address.street_1
        ? esc(deal.address.street_1.value)
        : 'No Address';
    const borrowerName = deal.borrower_name
      ? esc(deal.borrower_name.value)
      : 'No name';

    const loanServicerNumber =
      deal.loan && deal.loan.servicer_loan_number
        ? esc(deal.loan.servicer_loan_number.value)
        : 'No servicer loan number';

    const content = `
      <div class="row">
        <div class="col-4 col-xs-4 quick-search__id">
          #${esc(deal.id.value)}
          <br />
          <small>${toTitleCase(hit.status_code)}</small>
        </div>

        <div class="col-8 col-xs-8 quick-search__name">
          ${address}
          <br/>
          <small>${borrowerName}</small>
          <br/>
          <small>${loanServicerNumber}</small>
        </div>
      </div>
    `;

    if (isPersistent) {
      return `
        <div class="quick-search__link">${content}</div>
      `;
    }

    const hostname = window.location.hostname;
    let domain = 'invest.app.upright.us';

    if (hostname.includes('dev.notprod.live')) {
      domain = 'invest.app.upright.dev.notprod.live';
    }

    if (hostname.includes('stg.notprod.live')) {
      domain = 'invest.app.upright.stg.notprod.live';
    }

    if (hostname.includes('local.dev.notprod.live')) {
      domain = 'local.dev.notprod.live';
    }

    if (hostname.includes('monolith.bc.upright.us')) {
      domain = 'monolith.bc.upright.us';
    }

    return `<a data-turbolinks="false" class="quick-search__link" href="https://${domain}/admin/deals/${hit.id}/edit">${content}</a>`;
  } catch (error) {
    reportError(error, {
      data: { query: answer.query, deal },
    });

    return undefined;
  }
};

export default dealTemplate;
