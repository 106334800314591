import { Controller } from 'stimulus';
import { isNil, find } from 'lodash';

import { handleAJAXError } from '@ftf/lib/alerts';

export default class Remotetabs extends Controller {
  static targets = ['tab', 'contentPane'];

  connect() {
    const key = this.element.dataset.active || this.tabTargets[0].dataset.key;

    this.switchTab(key);

    this.tabTargets.forEach(el => {
      el.addEventListener('click', this.handleTabClick, false);
    });
  }

  disconnect() {
    this.tabTargets.forEach(el => {
      el.removeEventListener('click', this.handleTabClick, false);
    });
  }

  handleTabClick = e => {
    e.preventDefault();

    const { key } = e.target.dataset;

    if (!key) return;

    this.switchTab(key);
  };

  switchTab = key => {
    const hiddenField = document.getElementById('active-tab-projects');
    if (hiddenField) {
      hiddenField.value = key;
    }
    window.dispatchEvent(
      new CustomEvent('RemoteTabs:switchTab', { detail: { key } }),
    );

    this.tabTargets.forEach(el => {
      el.classList.toggle('is-active', el.dataset.key === key);
    });

    this.contentPaneTargets.forEach(el => {
      el.classList.toggle('is-active', el.dataset.key === key);
    });

    const activeTabContent = find(
      this.contentPaneTargets,
      el => el.dataset.key === key,
    );

    if (activeTabContent.childElementCount > 0) return;

    const activeTab = find(this.tabTargets, el => el.dataset.key === key);

    let { url } = activeTab.dataset;

    if (isNil(url)) return;

    const params = new URLSearchParams(document.location.search);
    params.forEach((value, param) => {
      if (param === 'type') return;
      url = `${url}&${param}=${value}`;
    });

    $.ajax({
      url,
      dataType: 'script',
      beforeSend: () => this.element.classList.add('is-loading'),
    })
      .fail(handleAJAXError)
      .always(() => this.element.classList.remove('is-loading'));
  };
}
