import { Controller } from 'stimulus';

export default class TableLink extends Controller {
  static targets = [];

  initialize() {
    this.element.addEventListener('click', this.handleClick);
  }

  handleClick = e => {
    if (e.srcElement.nodeName !== 'A' && e.srcElement.nodeName !== 'I') {
      const url = this.element.dataset.link;
      window.location = url;
    }
  };
}
