import { Controller } from 'stimulus';

export default class TinMaskingLogic extends Controller {
  static targets = [
    'viewMaskedTin',
    'viewTin',
    'unmaskTinButton',
    'maskTinButton',
  ];

  connect() {}

  disconnect() {}

  unmaskTin(event) {
    const unmaskedTarget = this.viewTinTargets.find(
      el => el.dataset.dealId === event.target.dataset.dealId,
    );
    const maskedTarget = this.viewMaskedTinTargets.find(
      el => el.dataset.dealId === event.target.dataset.dealId,
    );
    const unmaskTinButtonTarget = this.unmaskTinButtonTargets.find(
      el => el.dataset.dealId === event.target.dataset.dealId,
    );
    const maskTinButtonTarget = this.maskTinButtonTargets.find(
      el => el.dataset.dealId === event.target.dataset.dealId,
    );

    maskedTarget.style.display = 'none';
    unmaskTinButtonTarget.style.display = 'none';

    maskTinButtonTarget.style.display = 'block';
    unmaskedTarget.style.display = 'block';
  }

  maskTin(event) {
    const unmaskedTarget = this.viewTinTargets.find(
      el => el.dataset.dealId === event.target.dataset.dealId,
    );
    const maskedTarget = this.viewMaskedTinTargets.find(
      el => el.dataset.dealId === event.target.dataset.dealId,
    );
    const unmaskTinButtonTarget = this.unmaskTinButtonTargets.find(
      el => el.dataset.dealId === event.target.dataset.dealId,
    );
    const maskTinButtonTarget = this.maskTinButtonTargets.find(
      el => el.dataset.dealId === event.target.dataset.dealId,
    );

    maskedTarget.style.display = 'block';
    unmaskTinButtonTarget.style.display = 'block';

    unmaskedTarget.style.display = 'none';
    maskTinButtonTarget.style.display = 'none';
  }
}
